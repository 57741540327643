<template>
    <template v-if="isMobile">
        <MPill color="primary" v-model="model">
            {{ text }}
        </MPill>
    </template>
    <template v-else>
        <div class="form-check">
            <input class="form-check-input" :id="id" type="checkbox" value="" v-model="model">
            <label class="form-check-label" :for="id">
                {{ text }}
            </label>
        </div>
    </template>
</template>

<script setup>
import { computed } from "vue";
import { useAsyncComponent } from "o365-vue-utils";
import { isMobile } from 'o365.GlobalState.ts';
// import { globalState } from "o365-vue";

const MPill = useAsyncComponent('o365-mobile/MPill', {
    importFn: async () => {
        const mobile = await import('o365-mobile');
        return mobile.MPill;
    }
});

const props = defineProps({
    type: { type: String, default: "checkbox" },
    text: { type: String, required: true },
    modelValue: {},
});
const emit = defineEmits(["update:modelValue"]);

const model = computed({
    get: () => props.modelValue,
    set: (val) => emit("update:modelValue", val),
});

const id = crypto.randomUUID();
</script>
